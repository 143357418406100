import { createSlice } from '@reduxjs/toolkit'
import {
  getVisitsCensus,
  getVisitsList,
  getBetaVisitStatus,
  getFacilities,
  diagnosisSearch,
  cptSearch,
  getDischargeCodes,
} from './censusThunk'
import { format } from 'date-fns'

const initialState = {
  data: [],
  status: 'idle',
  error: null,
  dailysummary: [],
  visitsByDate: [],
  paymentTotal: 0,
  rvuTotal: 0,
  serviceDate: format(new Date(), 'yyyy-MM-dd'),
  visitStatuses: [],
  dischargeCodes: [],
  encounters: [],
  inPatients: [],
  activeDate: format(new Date(), 'yyyy-MM-dd'),
  activeTab: null,
  censusFacility: {},
  facilitiesList: [],
  allFacilitiesList: [],
  facilityObj: [],
  diagnosisList: [],
  diagnosisListFull: [],
  diagnosisTotalCount: 0,
  procedureCodes: [],
  providerHoldCount: 0,
  doRefresh: 0,
  patientSearchText: '',
  censusFilters: JSON.parse(localStorage.getItem('censusFilters')) || {},
  selectedProviderID: '',
  sortStatus: { columnName: null, ascending: true },
  providerSortStatus: { columnName: null, ascending: true },
  showFavoritesOnly: false,
}
const censusSlice = createSlice({
  name: 'census',
  initialState,
  reducers: {
    setActiveDate: (state, action) => {
      state.activeDate = action.payload
    },
    setSortStatus: (state, action) => {
      state.sortStatus = action.payload
    },
    setProviderSortStatus: (state, action) => {
      state.providerSortStatus = action.payload
    },

    setActiveTab: (state, action) => {
      state.activeTab = action.payload
    },

    setCensusFacility: (state, action) => {
      state.censusFacility = action.payload
    },
    setCensusFilters: (state, action) => {
      state.censusFilters = action.payload
      // the saddest requirement of page refresh persistence
      localStorage.setItem('censusFilters', JSON.stringify(action.payload))
    },
    setSelectedProviderID: (state, action) => {
      state.selectedProviderID = action.payload
    },
    setFacilitiesList: (state, action) => {
      state.facilitiesList = action.payload
    },

    setDiagnosisList: (state, action) => {
      state.diagnosisList = action.payload
    },

    setProcedureList: (state, action) => {
      state.procedureCodes = action.payload
    },
    setVisitsByDate: (state, action) => {
      state.visitsByDate = action.payload
    },
    setPatientSearchText: (state, action) => {
      state.patientSearchText = action.payload
    },
    setShowFavoritesOnly: (state, action) => {
      state.showFavoritesOnly = action.payload
    },
    resetCensusStore: (state, action) => {
      state.activeDate = initialState.activeDate
      state.activeTab = initialState.activeTab
      state.allFacilitiesList = initialState.allFacilitiesList
      state.diagnosisList = initialState.diagnosisList
      state.diagnosisListFull = initialState.diagnosisListFull
      state.encounters = initialState.encounters
      state.inPatients = initialState.inPatients
      state.procedureCodes = initialState.procedureCodes
      state.serviceDate = initialState.serviceDate
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFacilities.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(getFacilities.fulfilled, (state, action) => {
        state.isloading = false
        state.allFacilitiesList = action.payload?.allFacilitiesList
          ? action.payload?.allFacilitiesList
          : []
      })
      .addCase(getFacilities.rejected, (state, action) => {
        state.isloading = false
        state.isError = true
        if (action.payload) {
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })
      .addCase(getVisitsCensus.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(getVisitsCensus.fulfilled, (state, action) => {
        state.isloading = false
        if (action.payload) {
          state.visitsByDate = action.payload?.visitsByDate
          state.rvuTotal = action.payload?.rvuTotal ? action.payload?.rvuTotal : 0
          state.paymentTotal = action.payload?.paymentTotal ? action.payload?.paymentTotal : 0
          state.providerHoldCount = action.payload?.providerHoldCount
            ? action.payload?.providerHoldCount
            : 0
          state.inPatients = action.payload?.inPatients ? action.payload?.inPatients : []
          state.visitStatuses = action.payload?.visitStatuses ? action.payload?.visitStatuses : []
          state.dischargeCodes = action.payload?.dischargeCodes
            ? action.payload?.dischargeCodes
            : []
          state.encounters = action.payload?.encounters ? action.payload?.encounters : []
          state.diagnosisList = action.payload?.diagnosisList ? action.payload?.diagnosisList : []
          state.diagnosisListFull = action.payload?.diagnosisList
            ? action.payload?.diagnosisList
            : []
          state.procedureCodes = action.payload?.procedureCodes
            ? action.payload?.procedureCodes
            : []
          state.diagnosisTotalCount = action.payload?.diagnosisTotalCount
            ? action.payload?.diagnosisTotalCount
            : 0
          state.serviceDate = action.payload?.serviceDate
            ? action.payload?.serviceDate
            : initialState.serviceDate
          state.doRefresh = state.doRefresh + 1
          state.docByFacilityList = action.payload?.docByFacilityList
        }
      })
      .addCase(getVisitsCensus.rejected, (state, action) => {
        state.isloading = false
        state.isError = true
        if (action.payload) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })
      .addCase(getVisitsList.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(getVisitsList.fulfilled, (state, action) => {
        state.isloading = false
        state.visitsByDate = action.payload?.visitsByDate
        state.doRefresh = state.doRefresh + 1
        state.docByFacilityList = action.payload?.docByFacilityList
      })
      .addCase(getVisitsList.rejected, (state, action) => {
        state.isloading = false
        state.isError = true
        if (action.payload) {
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })
      .addCase(getBetaVisitStatus.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(getBetaVisitStatus.fulfilled, (state, action) => {
        state.isloading = false
        state.visitStatuses = action?.payload?.visitStatuses?.results[0]?.values
      })
      .addCase(getBetaVisitStatus.rejected, (state, action) => {
        state.isloading = false
        state.isError = true
        if (action.payload) {
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })
      .addCase(getDischargeCodes.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(getDischargeCodes.fulfilled, (state, action) => {
        state.isloading = false
        state.dischargeCodes = action?.payload?.dischargeCodes?.results[0]?.values
      })
      .addCase(getDischargeCodes.rejected, (state, action) => {
        state.isloading = false
        state.isError = true
        if (action.payload) {
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })
      .addCase(diagnosisSearch.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(diagnosisSearch.fulfilled, (state, action) => {
        state.isloading = false
        state.diagnosisList = action?.payload
      })
      .addCase(diagnosisSearch.rejected, (state, action) => {
        state.isloading = false
        state.isError = true
        if (action.payload) {
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })
      .addCase(cptSearch.pending, (state, action) => {
        state.isloading = true
      })
      .addCase(cptSearch.fulfilled, (state, action) => {
        state.isloading = false
        state.procedureCodes = action?.payload
      })
      .addCase(cptSearch.rejected, (state, action) => {
        state.isloading = false
        state.isError = true
        if (action.payload) {
          state.errorMsg = action.payload
        } else {
          state.errorMsg = action.error.message?.toString()
        }
      })
  },
})

export const {
  setActiveTab,
  setActiveDate,
  setCensusFacility,
  setFacilitiesList,
  setDiagnosisList,
  setProcedureList,
  setVisitsByDate,
  setPatientSearchText,
  setCensusFilters,
  setSelectedProviderID,
  resetCensusStore,
  setSortStatus,
  setProviderSortStatus,
  setShowFavoritesOnly,
} = censusSlice.actions
export const selectCensus = (state) => state.census
export default censusSlice.reducer
